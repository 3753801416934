import Link from 'next/link';
import useFetch from '../../lib/hooks/useFetch';

const categoryBlackList = [
    'articles',
    'desert-hope',
    'desert-hope-outpatient',
    'facility-page-only',
    'adolescents-facility',
    'adult-facility',
    'binge-eating-facility',
    'outpatient-facility',
    'residential-facility',
    'featured',
    'recipes',
    'greenhouse-2',
    'greenhouse-outpatient',
    'singer-island',
    'drug-facts',
    'uncategorized',
];

const BlogCategories = () => {
    // /wordpress-api/categories?per_page=40&_fields=id,slug,name

    let {data: categories} = useFetch(
        '/wordpress-api/categories?per_page=40&_fields=id,slug,name',
    );
    categories = categories.filter((x) => !categoryBlackList.includes(x.slug));

    return (
        <div className="categories">
            <div className="text-xl bold categories__title">Categories</div>
            <div className="categories__list">
                <ul>
                    {Array.isArray(categories) &&
                        categories.map((category) => {
                            return (
                                <li key={category?.id}>
                                    <Link href={`/blog/category/${category?.slug}`} legacyBehavior>
                                        <a
                                            title={category?.name}
                                            dangerouslySetInnerHTML={{
                                                __html: category?.name,
                                            }}
                                        />
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <style jsx>
                {`
                    .categories {
                        background: var(--tertiary-300);
                        border-radius: 8px;
                        padding: 24px 16px;
                    }
                    .categories__title {
                        margin-bottom: 16px;
                        color: var(--primary-400);
                    }
                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                    }
                    a {
                        color: var(--secondary-300);
                        margin-bottom: 16px;
                        display: block;
                    }
                `}
            </style>
        </div>
    );
};
export default BlogCategories;
