import React from 'react';
import dynamic from 'next/dynamic';
import {withRouter} from 'next/router';
import Link from 'next/link';
import fetch from 'aac-components/utils/fetch';
import {shouldShowError} from '../../lib/utils';
import Layout from '../../components/Layout';
import {BREAKPOINT} from '../../lib/styles';
import Title from '../../components/Title';
import MaxWidth from '../../components/MaxWidth';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import {shortcodes} from '../../lib/shortcodes';
import CustomError from '../_error';
import {TagCluster} from '../../components/ContentTags';
import BreadcrumbsWrapper from '../../components/Breadcrumbs';
import BlogCategories from '../../components/BlogCategories';
import Pagination from 'aac-components/components/Pagination';
import styled from 'styled-components';
import {getPageByPath} from '../../lib/post';

const BlogCard = dynamic(() => import('../../components/BlogCard'));

const perPage = 12;

const PaginationStyles = styled.div`
    .pagination__ul {
        margin: 60px auto;
        display: flex;
        justify-content: center;
    }
`;

class Blog extends React.Component {
    constructor(props) {
        super(props);

        this.fetchPosts = this.fetchPosts.bind(this);
    }

    state = {};

    async fetchPosts(pageNumber) {
        const isFirstPage = pageNumber == 0 || pageNumber == 1;
        const offset = isFirstPage ? 0 : (pageNumber - 1) * perPage + 1;

        const url = `/wordpress-api/posts?per_page=${perPage}&offset=${offset}&_fields=id,date,excerpt,link,slug,tags,title`;
        const result = await fetch(url);
        const data = await result.json();

        this.setState({
            posts: data,
        });
    }

    getLinkProps = (pageNumber) => {
        return {href: `/blog?page=${pageNumber}`};
    };

    render() {
        const hasError = shouldShowError(this.props);
        if (hasError) {
            return <CustomError statusCode={hasError} />;
        }

        console.log(this.props);

        // featured post
        const featuredPost = this.props?.featuredPost?.[0] || [];
        const excerpt = featuredPost?.excerpt?.rendered || '';
        const title = featuredPost?.title?.rendered || '';
        const featuredSlug = featuredPost?.slug || '';
        const featuredId = featuredPost?.id || '';
        const featuredDate = featuredPost?.date || '';
        const featuredTags = featuredPost?.tags || [];
        const formattedDate = format(parse(featuredDate), 'MMMM D, YYYY');

        const total = this.props?.totalPosts;

        const pageNumber = this.props?.pageNumber;
        const posts = this.state?.posts || this.props?.posts || [];

        return (
            <Layout>
                <BreadcrumbsWrapper />
                <Title
                    dangerouslySetInnerHTML={{__html: 'American Addiction Centers Blog'}}
                />
                <MaxWidth style={{marginBottom: '60px'}}>
                    <div className="latest">
                        <div className="latest__subtitle">Latest Article</div>
                        <h3 style={{marginBottom: '10px'}}>
                            <Link href={`/blog/${featuredSlug}`} legacyBehavior>
                                <a style={{fontSize: '25px'}}>
                                    <span
                                        dangerouslySetInnerHTML={{__html: title}}
                                        className="latest__title"
                                    />
                                </a>
                            </Link>
                        </h3>
                        <p className="featured__date">{formattedDate}</p>
                        <div dangerouslySetInnerHTML={{__html: excerpt}} />
                        <TagCluster tags={featuredTags} />
                    </div>
                    <div className="container">
                        <div className="content">
                            {Array.isArray(posts) && (
                                <div className="cards">
                                    {posts.map((post) => {
                                        const {id} = post;
                                        if (id === featuredId) return null;
                                        return (
                                            <React.Fragment key={id}>
                                                <BlogCard post={post} />
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            )}
                            {total > perPage && (
                                <PaginationStyles>
                                    <Pagination
                                        perPage={perPage}
                                        total={total}
                                        currentPage={pageNumber}
                                        urlBase="/blog"
                                    />
                                </PaginationStyles>
                            )}
                        </div>
                        <div>
                            <BlogCategories />
                        </div>
                    </div>
                </MaxWidth>
                <style global jsx>
                    {shortcodes}
                </style>
                <style global jsx>
                    {`
                        p {
                            margin: 0;
                        }
                    `}
                </style>
                <style jsx>{`
                    h1 {
                        padding-top: 50px;
                    }
                    .container {
                        display: block;
                        grid-template-columns: 1fr;
                        margin: 0 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            display: grid;
                            grid-template-columns: 3fr 1fr;
                            grid-gap: 30px;
                            margin: 0;
                        }
                    }

                    .latest__subtitle {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 15px;
                        top: 15px;
                        left: 20px;
                    }
                    .latest {
                        background-color: #fff;
                        box-shadow: var(--box-shadow);
                        padding: 30px 20px;
                        text-align: left;
                        font-size: 18px;
                        margin: 30px 15px;
                        position: relative;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .latest {
                            margin: 0 0 60px 0;
                        }
                    }
                    .latest__title {
                        color: var(--interactive);
                        text-decoration: underline;
                    }
                    .cards {
                        display: grid;
                        grid-gap: 30px;
                        grid-template-columns: 1fr;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .cards {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }

                    .featured__date {
                        font-size: 13px;
                        margin: 0 0 15px 0;
                    }
                `}</style>
            </Layout>
        );
    }
}

export default withRouter(Blog);

export async function getServerSideProps(context) {
    const pageNumber = context?.query?.page || 1;
    const isFirstPage = pageNumber == 0 || pageNumber == 1;
    const offset = isFirstPage ? 0 : (pageNumber - 1) * perPage + 1;
    const url = `/wordpress-api/posts?per_page=${perPage}&offset=${offset}&_fields=id,date,excerpt,link,slug,tags,title`;

    const wpPage = await getPageByPath(
        context,
        '?_fields=id,date,excerpt,slug,tags,title,yoast_head',
    );

    let response = {};
    let data = [];

    try {
        response = await Promise.all([
            fetch(url),
            fetch('/wordpress-api/posts?per_page=1&page=1&'),
        ]);
        data = await Promise.all([response[0].json(), response[1].json()]);
    } catch (e) {
        return {props: {data: [], error: e}};
    }

    const posts = data?.[0] || [];
    const featuredPost = data?.[1] || {};
    const totalPosts = Number(response?.[0]?.headers.get('x-wp-total')) || 0;

    // set 404 status code
    if (wpPage?.id === 0) {
        context.res.statusCode = 404;
    }

    return {
        props: {
            data: wpPage,
            posts,
            featuredPost,
            totalPosts,
            pageNumber: +pageNumber,
        },
    };
}
