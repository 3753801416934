import MaxWidth from '../MaxWidth';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import HiddenOnMobile from 'aac-components/components/HiddenOnMobile';
import styled from 'styled-components';

const BreadcrumbsStyles = styled.div`
    .breadcrumbs {
        margin: 0 auto;
        min-height: 60px;
        display: flex;
    }
`;

const BreadcrumbsWrapper = () => {
    return (
        <HiddenOnMobile>
            <MaxWidth>
                <div className="breadcrumbs-placeholder">
                    <BreadcrumbsStyles>
                        <Breadcrumbs />
                    </BreadcrumbsStyles>
                </div>
            </MaxWidth>
        </HiddenOnMobile>
    );
};
export default BreadcrumbsWrapper;
